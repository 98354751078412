import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  "Deep JS Training": "a9296b6938d8",

  EventLoop: "1986a06b0f94",
  "Stack vs Heap": "1986a06b0f94",
  "Call Stack2": "1986a06b0f94",

  "Execution Context": "407d1dba185c",
  "Lexical Environment": "407d1dba185c",
  "Object Model": "407d1dba185c",
  Hoisting: "407d1dba185c",
  Scope: "407d1dba185c",
  "Scope Chain": "407d1dba185c",
  Clousure: "407d1dba185c",

  "Data Types": "739fb8df0ce8",
  "Primitive Types": "739fb8df0ce8",
  "Object Types": "739fb8df0ce8",
  Immutables: "739fb8df0ce8",

  Operators: "6c4a13a1743",
  "Math Operators": "6c4a13a1743",
  "Increment/Decrement Operators": "6c4a13a1743",
  "Equality Operators": "6c4a13a1743",
  "Compare Operators": "6c4a13a1743",
  "Logical Operators": "6c4a13a1743",
  "Bitwise Operators": "6c4a13a1743",
  "Assignment Operators": "6c4a13a1743",
  "Bitwise Shift Operators": "6c4a13a1743",
  "Conditional Operators": "6c4a13a1743",

  "Abstract Syntax Tree": "e6d0b33f147",
  ByteCode: "e6d0b33f147",
  "Optimizing Compiler": "e6d0b33f147",
  "Optimized Code": "e6d0b33f147",

  Value: "e6d0b33f147",
  Writable: "e6d0b33f147",
  Enumerable: "e6d0b33f147",
  Configurable: "e6d0b33f147",

  __proto__: "e6d0b33f147",
  prototype: "e6d0b33f147",
  inheritance: "e6d0b33f147",

  inheritance2: "5834c38a9134",

  "Arrow Function": "63cc425a8966",
  "Regular Function": "63cc425a8966",
  "Anonymous Function": "63cc425a8966",
  "Pure Function": "63cc425a8966",
  "Constructor Function": "63cc425a8966",
  IIFE: "63cc425a8966",
  "High Order Function": "63cc425a8966",
  "Synchronous Function": "63cc425a8966",
  "Asynchronous Function": "63cc425a8966",
  "Generator Function": "63cc425a8966",

  this: "77d548e969ae",
  "bind, call, apply": "77d548e969ae",

  "private field": "c2f825e4a76",
  "public field": "c2f825e4a76",

  "DeepJS-prototype": "1ccd29b513b7",
  "DeepJS-__proto__": "1ccd29b513b7",

  "Mutable, Immutable": "d0cab0c1f30f",
  "Primitive, ObjectType": "d0cab0c1f30f",
  "Is Function an Object": "d0cab0c1f30f",
  "Arrow vs Normal Function": "d0cab0c1f30f",
  "Function Arguments": "d0cab0c1f30f",

  "JS Object.Assign": "422fb4693175",
  "JS Object Spread": "422fb4693175",
}

const eBooks = [
  {
    title: "JS Derinlemesine Öğren",
    url: "https://learnreactui.dev/contents/javascript-deep-learning",
    img:
      "https://storage.googleapis.com/argon-app-web-prod/assets%2F8c518a99-09a4-4c87-ac5d-2ad82c81139f%2Fcovers%2FCover.png",
  },
]

const stories = [
  {
    title: "Derinlemesine JS Öğren Eğitim İçeriği",
    postId: "a9296b6938d8",
  },
  {
    title: "JS'de EventLoop",
    postId: "1986a06b0f94",
  },
  {
    title: "Execution Context, Scope, Clousures",
    postId: "407d1dba185c",
  },
  {
    title: "JS Veri Türleri",
    postId: "739fb8df0ce8",
  },
  {
    title: "typeof(null) neden object",
    postId: "fa8000d0a16",
  },
  {
    title: "JS Operator",
    postId: "6c4a13a1743",
  },
  {
    title: "JS Object Yapısı",
    postId: "e6d0b33f147",
  },
  {
    title: "Abstract Syntax Tree",
    postId: "4d7c6918b5d5",
  },
  {
    title: "JS’te prototype, __proto__ ",
    postId: "1ccd29b513b7",
  },
  {
    title: "JS’te Kalıtım(Inheritance)",
    postId: "5834c38a9134",
  },
  {
    title: "JS Fonksiyonlar",
    postId: "63cc425a8966",
  },
  {
    title: "JS’de Fonksiyon’un Gizemli Dünyası",
    postId: "d0cab0c1f30f",
  },
  {
    title: "JS’de this ve apply/call/bind",
    postId: "77d548e969ae",
  },
  {
    title: "JS Proposal Class Fields",
    postId: "c2f825e4a76",
  },
  {
    title: "JS Assign vs Spread",
    postId: "422fb4693175",
  },
  {
    title: "JS Object Merge/extend",
    postId: "7fe5ad610a2b",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "JS Deep Learning",
  path: "javascript-deep-learning",
}

const JSDeepLearningPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
      eBooks={eBooks}
    />
  )
}

export default JSDeepLearningPage
